.photobooth {
  height: 100vh;
  padding: 2em;
}

.prompt {
  margin-bottom: 1em;
}

.photobooth canvas {
  width: 945px;
  height: 945px;
}

.actions {
  padding: 1em;
  font-size: 1.5em;
}
