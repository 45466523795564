.bingo-container {
  --bingo-grid-size: 4;
}

.bingo-board {
  display: grid;
  grid-template-columns: repeat(var(--bingo-grid-size), 1fr);
  grid-gap: 1px;
  height: 100vh;
}

.bingo-row {
}

.bingo-cell {
  position: relative;
  overflow: hidden;
  padding: 2em;
  border: 1px solid #eeaaa8;
  height: calc(100vh / var(--bingo-grid-size));
  width: calc(100vw / var(--bingo-grid-size) - 0.5em);
}

.bingo-cell.selected {
  background-color: #eeaaa8;
}

.bingo-cell:hover::before {
  content: '✓';
  position: absolute;
  top: 35%;
  left: 0;
  width: 100%;
  font-size: 100px;
  color: #ccc;
}

.bingo-cell.selected::before {
  content: '✓';
  position: absolute;
  top: 35%;
  left: 0;
  width: 100%;
  font-size: 100px;
  color: green;
}

.bingo-cell.selected em {
  color: #000;
}

.bingo-cell:hover {
  background-color: #f3d7d4;
  cursor: pointer;
}

.bingo-cell.selected:hover {
  background-color: #eeaaa8;
}

.bingo-cell img {
  max-width: min(100px, calc(100vw / var(--bingo-grid-size) / 4));
  max-height: min(100px, calc(100vh / var(--bingo-grid-size) / 4));
}

.bingo-cell em {
  color: #ccc;
}

.win-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 40px;
  background-color: #f3d7d4;
  padding: 0.5em 0;
}
