.main {

  margin: 15% auto;
  max-width: 960px;
}

.form {
  display: flex;
  margin-top: 3em;
}

.col {
  flex: 1;
}

label {
  font-weight: bold;
  font-size: 24px;
}

select {
  font-size: 24px;
}

.main .cta {
  margin-top: 3em;
  font-size: 24px;
  padding: 0.5em;
}

.result {
  margin-top: 3em;
}
