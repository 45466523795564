.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/

.App-header {
  background-color: #862020;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.cta {
  color: #fff;
  font-size: 1em;
  background-color: #ee770e;
  border-radius: 8px;
  border: none;
  padding: 0.25em;
  cursor: pointer;
}

.cta:hover {
  background-color: #f7c211;
}

.upload-link-container {
  margin-top: 1em;
  font-size: 0.5em;
  color: #ccc;
}

.upload-link-container a {
  color: #ccc;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
